<template>
    <section class="accueil" v-if="data">
        <div>
            <img src="https://cpts.comolapapaya.com/img/couverture.b863c588.png" alt="">
        </div>
        <div class="container">
            <div class="row mt-5">
                <div class="d-flex justify-content-around">
                    <!-- text1 -->
                    <tt-editor v-if="modeEdit" :value="data.text.text1" @input="data.text.text1 = $event" ></tt-editor>
                     <span v-else v-html="data.text.text1">
                     </span>
                     <!-- end text1 -->

                     <!-- image1 -->
                    <img :src="data.image.image1.url" :alt="data.image.image1.alt">
                    <div v-if="modeEdit">
                    <add-image
                        :modal="'img' + data.image.image1.url"
                        @saveImage="data.image.image1.url = $event['image']"
                    />
                    </div>
                    <!-- end image1 -->

                </div>
            </div>
            <div class="row text-center">
                <!-- text2 -->
                <tt-editor v-if="modeEdit" :value="data.text.text2" @input="data.text.text2 = $event" ></tt-editor>
                <span v-else v-html="data.text.text2">                       
                </span>
                <!-- end text2 -->
            </div>
        </div>
        <hr>
        <!-- A propos -->
        <div class="container">
            <div class="d-flex card-type-4">
                <div class="d-flex flex-column justify-content-around p-4 text-center">
                    <!-- text3 -->
                    <tt-editor v-if="modeEdit" :value="data.text.text3" @input="data.text.text3 = $event" ></tt-editor>
                    <span v-else v-html="data.text.text3">
                    </span>
                    <!-- end text3 -->

                    <!-- btn1 -->
                    <template v-if="modeEdit">
                        <button class="btn btn-primary btn-rounded" :id="'btn-1'" variant="primary" >
                        <span v-html="data.btn.btn1.text">
                        </span>  
                        
                        </button>
                        <b-popover :target="'btn-1'" placement="top"  triggers="hover focus" >
                        <b-form-group label="Text" label-for="textbtn1">
                            <b-form-input type="text" id="textbtn1" v-model="data.btn.btn1.text" placeholder="Texte" size="lg"></b-form-input>
                        </b-form-group> 
                        <b-form-group label="Url" label-for="urlbtn1">
                            <b-form-input type="text" id="urlbtn1" v-model="data.btn.btn1.url" placeholder="Url" size="lg"></b-form-input>
                        </b-form-group> 
                        </b-popover>
                    </template>
                    <button v-else class="btn btn-primary btn-rounded" v-html="data.btn.btn1.text">
                    </button>
                    <!-- end btn1 -->

                </div>
                <span class="border-right"></span>
                <div class="d-flex flex-column justify-content-around p-4 text-center">
                    <!-- text4 -->
                    <tt-editor v-if="modeEdit" :value="data.text.text4" @input="data.text.text4 = $event" ></tt-editor>
                    <span v-else  v-html="data.text.text4">
                    </span>
                    <!-- end text4 -->

                    <!-- btn2 -->
                    <template v-if="modeEdit">
                        <button class="btn btn-primary btn-rounded" :id="'btn-2'" variant="primary" >
                        <span v-html="data.btn.btn2.text">
                        </span>  
                        </button>
                        <b-popover :target="'btn-2'" placement="top" title="Edit!" triggers="hover focus" >
                            <b-form-group label="Text" label-for="textbtn2">
                            <b-form-input type="text" id="textbtn2" v-model="data.btn.btn2.text" placeholder="Texte" size="lg"></b-form-input>
                            </b-form-group> 
                            <b-form-group label="Url" label-for="urlbtn2">
                                <b-form-input type="text" id="urlbtn2" v-model="data.btn.btn2.url" placeholder="Url" size="lg"></b-form-input>
                            </b-form-group> 
                        </b-popover>
                    </template>
                    <button v-else class="btn btn-primary btn-rounded">
                    <!-- <tt-editor v-if="modeEdit" :value="data.btn2" @input="data.btn2 = $event" ></tt-editor> -->
                        <span v-html="data.btn.btn2.text">                               
                        </span>
                    </button>

                    <!-- end btn2 -->
                </div>
                <span class="border-right"></span>
                <div class="d-flex flex-column justify-content-around p-4 text-center">
                     <!-- text5 -->
                    <tt-editor v-if="modeEdit" :value="data.text.text5" @input="data.text.text5 = $event" ></tt-editor>
                    <span v-else v-html="data.text.text5">
                    </span>
                    <!-- end text5 -->

                    <!-- btn3 -->
                    <template v-if="modeEdit">
                        <button class="btn btn-primary btn-rounded" :id="'btn-3'" variant="primary" >
                        <span v-html="data.btn.btn3.text">
                        </span>  
                       
                        </button>
                        <b-popover :target="'btn-3'" placement="top" title="Edit!" triggers="hover focus" >
                            <b-form-group label="Text" label-for="textbtn3">
                                <b-form-input type="text" id="textbtn3" v-model="data.btn.btn3.text" placeholder="Texte" size="lg"></b-form-input>
                            </b-form-group> 
                            <b-form-group label="Url" label-for="urlbtn3">
                                <b-form-input type="text" id="urlbtn3" v-model="data.btn.btn3.url" placeholder="Url" size="lg"></b-form-input>
                            </b-form-group> 
                        </b-popover>
                    </template>
                    <button v-else class="btn btn-primary btn-rounded">
                        <!-- <tt-editor v-if="modeEdit" :value="data.btn3" @input="data.btn3 = $event" ></tt-editor> -->
                        <span v-html="data.btn.btn3.text">
                        </span>
                    </button>
                    <!--end btn3 -->
                </div>
            </div>
        </div>
        <hr>
        <!-- CPTS Chiffre -->
        <div class="cpts-numbers mb-5">
            <div class="container">
                <h3 class="text-center">La CPTS en chiffre</h3>
                <div class="d-flex justify-content-around">
                    <div class="card">
                        <div class="card-body text-center">
                            <!-- text6 -->
                            <tt-editor v-if="modeEdit" :value="data.text.text6" @input="data.text.text6 = $event" ></tt-editor>
                            <span v-else v-html="data.text.text6">
                            </span>
                            <!-- end text6 -->
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body text-center">
                            <!-- text7 -->
                            <tt-editor v-if="modeEdit" :value="data.text.text7" @input="data.text.text7 = $event" ></tt-editor>
                            <span v-else v-html="data.text.text7">
                            </span>
                            <!-- end text7 -->
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body text-center">
                            <!-- text8 -->
                            <tt-editor v-if="modeEdit" :value="data.text.text8" @input="data.text.text8 = $event" ></tt-editor>
                            <span v-else v-html="data.text.text8">
                            </span>
                            <!-- end text8 -->
                        </div>
                    </div>
                </div>
                <div class="external-card d-flex justify-content-center mt-5">
                    <div class="card">
                        <div class="card-body">
                           <div class="card-body text-center">
                                <!-- text9 -->
                                <tt-editor v-if="modeEdit" :value="data.text.text9" @input="data.text.text9 = $event" ></tt-editor>
                                <span v-else v-html="data.text.text9">
                                </span>
                                <!-- end text9 -->
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container text-center">
            <div class="row">
                <!-- text10 -->
                <tt-editor v-if="modeEdit" :value="data.text.text10" @input="data.text.text10 = $event" ></tt-editor>
                <span v-else v-html="data.text.text10">
                </span>
                <!-- end text10    -->
            </div>
        </div>

        <!-- card-type-2/ -->
        <div class="container">
            <div class="d-flex justify-content-around card-type-2">
                <div class="card card-rounded-1 m-4">
                    <div class="card-body d-flex flex-column justify-content-center text-center">
                        <i class="fa fa-users"></i>
                        <!-- text11 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text11" @input="data.text.text11 = $event" ></tt-editor>
                        <span v-else v-html="data.text.text11">
                        </span>
                        <!-- end text11 -->
                    </div>
                </div>
                <div class="card card-rounded-2 m-4">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                        <!-- image2 -->
                        <img :src="data.image.image2.url" :alt="data.image.image2.alt"  >
                        <div v-if="modeEdit">
                        <add-image
                            :modal="'img' +data.image.image2.url"
                            @saveImage="data.image.image2.url = $event['image']"
                        />
                        </div>
                        <!-- end image2  -->
                        
                        <!-- text12 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text12" @input="data.text.text12 = $event" ></tt-editor>
                        <span v-else v-html="data.text.text12">                              
                        </span>
                         <!-- end text12 -->
                    </div>
                </div>
                <div class="card card-rounded-1 m-4">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                        <!-- image3 -->
                        <img :src="data.image.image3.url" :alt="data.image.image3.alt">
                        <div v-if="modeEdit">
                        <add-image
                            :modal="'img' + data.image.image3.url"
                            @saveImage="data.image.image3.url = $event['image']"
                        />
                        </div>
                        <!-- end image3 -->

                        <!-- text13 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text13" @input="data.text.text13 = $event" ></tt-editor>
                        <span v-else v-html="data.text.text13">
                        </span>
                        <!-- end text13 -->
                        
                    </div>
                </div>
                <div class="card card-rounded-2 m-4">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                        <!-- image4 -->
                        <img :src="data.image.image4.url" :alt="data.image.image4.alt">
                        <div v-if="modeEdit">
                        <add-image
                            :modal="'img' + data.image.image4.url"
                            @saveImage="data.image.image4.url = $event['image']"
                        />
                        </div>
                        <!-- end image4  -->

                        <!-- text14 -->
                            <tt-editor v-if="modeEdit" :value="data.text.text14" @input="data.text.text14 = $event" ></tt-editor>
                            <span v-html="data.text.text14">                                    
                            </span>
                        <!-- end text14 -->
                    </div>
                </div>
            </div>
        </div>
        <!-- .card-type-3 -->
        <div class="sign-in position-relative card-type-3">
            <div class="container d-flex justify-content-around">
                <div class="card">
                    <div class="card-header">
                        <!-- text15 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text15" @input="data.text.text15 = $event" ></tt-editor>
                        <span v-else v-html="data.text.text15">
                        </span>
                        <!-- end text15  -->
                    </div>
                    <div class="card-body text-center">
                        <!-- text16 -->
                            <tt-editor v-if="modeEdit" :value="data.text.text16" @input="data.text.text16 = $event" ></tt-editor>
                            <span v-else v-html="data.text.text16">
                            </span>
                        <!-- end text16 -->

                        <!-- btn4 -->
                        <template v-if="modeEdit">
                            <button class="btn btn-primary btn-rounded" :id="'btn-4'" variant="primary" >
                            <span v-html="data.btn.btn4.text">
                            </span>  
                            
                            </button>
                            <b-popover :target="'btn-4'" placement="top" title="Edit!" triggers="hover focus" >
                                <b-form-group label="Text" label-for="textbtn4">
                                <b-form-input type="text" id="textbtn4" v-model="data.btn.btn4.text" placeholder="Texte" size="lg"></b-form-input>
                                </b-form-group> 
                                <b-form-group label="Url" label-for="urlbtn4">
                                    <b-form-input type="text" id="urlbtn4" v-model="data.btn.btn4.url" placeholder="Url" size="lg"></b-form-input>
                                </b-form-group> 
                            </b-popover>
                        </template>
                        
                        <button v-else class="btn btn-primary btn-rounded">
                            <span v-html="data.btn.btn4.text">
                            </span>
                        </button>
                         <!-- end btn4 -->
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <!-- text17 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text17" @input="data.text.text17 = $event" ></tt-editor>
                        <span v-else  v-html="data.text.text17">
                        </span>
                        <!-- end text17 -->
                    </div>
                    <div class="card-body text-center">
                        <!-- text18 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text18" @input="data.text.text18 = $event" ></tt-editor>
                        <span v-html="data.text.text18">
                        </span>
                        <!-- end text18 -->

                        <!-- btn5 -->
                        <template v-if="modeEdit">
                            <button class="btn btn-primary btn-rounded" :id="'btn-5'" variant="primary" >
                            <span v-html="data.btn.btn5.text">
                            </span>  
                            
                            </button>
                            <b-popover :target="'btn-5'" placement="top" title="Edit!" triggers="hover focus" >
                                <b-form-group label="Text" label-for="textbtn5">
                                    <b-form-input type="text" id="textbtn5" placeholder="Texte" v-model="data.btn.btn5.text" size="lg"></b-form-input>
                                </b-form-group> 
                                <b-form-group label="Url" label-for="urlbtn5">
                                    <b-form-input type="text" id="urlbtn5" v-model="data.btn.btn5.url" placeholder="Url" size="lg"></b-form-input>
                                </b-form-group> 
                            </b-popover>
                        </template>
                        <button v-else class="btn btn-primary btn-rounded" v-html="data.btn.btn5.text"></button>
                        <!-- end btn5 -->
                    </div>
                </div>
            </div>
            <!-- image5 -->
            <img class="sign-in-img" :src="data.image.image5.url" :alt="data.image.image5.alt">
            <div v-if="modeEdit">
            <add-image
                :modal="'img' + data.image.image5.url"
                @saveImage="data.image.image5.url = $event['image']"
            />
            </div>
            <!-- end image5  -->
        </div>

        <!-- A propos--2  -->
        <div class="container">
            <div class="d-flex card-type-4">
                <div class="card-invi d-flex flex-column justify-content-around p-4 text-center">
                    <!-- text19 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text19" @input="data.text.text19 = $event" ></tt-editor>
                        <span v-else v-html="data.text.text19">
                        </span>
                    <!-- end text19 -->

                    <!-- btn6 -->
                    <template v-if="modeEdit">
                            <button class="btn btn-primary btn-rounded" :id="'btn-6'" variant="primary" >
                            <span v-html="data.btn.btn6.text">
                            </span>  
                            
                            </button>
                            <b-popover :target="'btn-6'" placement="top" title="Edit!" triggers="hover focus" >
                                <b-form-group label="Text" label-for="textbtn6">
                                    <b-form-input type="text" :id="data.btn.btn6.text" v-model="data.btn.btn6.text" placeholder="Texte" size="lg"></b-form-input>
                                </b-form-group> 
                                <b-form-group label="Url" label-for="urlbtn6">
                                    <b-form-input type="text" :id="data.btn.btn6.url" v-model="data.btn.btn6.url" placeholder="Url" size="lg"></b-form-input>
                                </b-form-group> 
                            </b-popover>
                        </template>
                    <button v-else class="btn btn-primary btn-rounded">
                        <span v-html="data.btn.btn6.text">
                        </span>
                    </button>
                    <!-- end btn6 -->
                </div>
                <span class="border-right"></span>
                <div class="card-invi d-flex flex-column justify-content-around p-4 text-center">
                    <!-- text20 -->
                        <tt-editor v-if="modeEdit" :value="data.text.text20" @input="data.text.text20 = $event" ></tt-editor>
                        <span v-else v-html="data.text.text20">
                        </span>
                    <!-- end text20 -->

                    <!-- btn7 -->
                    <template v-if="modeEdit">
                            <button class="btn btn-primary btn-rounded" :id="'btn-7'" variant="primary" >
                            <span v-html="data.btn.btn7.text">
                            </span>  
                            
                            </button>
                            <b-popover :target="'btn-7'" placement="top" title="Edit!" triggers="hover focus" >
                                <b-form-group label="Text" label-for="textbtn7">
                                <b-form-input type="text" :id="data.btn.btn7.text" v-model="data.btn.btn7.text" placeholder="Texte" size="lg"></b-form-input>
                            </b-form-group> 
                            <b-form-group label="Url" label-for="urlbtn7">
                                <b-form-input type="text" :id="data.btn.btn7.url" v-model="data.btn.btn7.url" placeholder="Url" size="lg"></b-form-input>
                            </b-form-group> 
                            </b-popover>
                    </template>
                    <button v-else class="btn btn-primary btn-rounded">
                        <span v-html="data.btn.btn7.text">
                        </span>
                    </button>
                    <!-- end btn7 -->
                </div>
                <span class="border-right"></span>
                <div class="card-invi d-flex flex-column justify-content-around p-4 text-center">
                    <!-- text21  -->
                    <tt-editor v-if="modeEdit" :value="data.text.text21" @input="data.text.text21 = $event" ></tt-editor>
                    <span v-else v-html="data.text.text21">
                    </span>
                    <!-- end text21  -->

                    <!-- btn8 -->
                    <template v-if="modeEdit">
                            <button class="btn btn-primary btn-rounded" :id="'btn-8'" variant="primary" >
                            <span v-html="data.btn.btn8.text">
                            </span>  
                           
                            </button>
                            <b-popover :target="'btn-8'" placement="top" title="Edit!" triggers="hover focus" >
                             <b-form-group label="Text" label-for="textbtn8">
                                <b-form-input type="text" :id="data.btn.btn8.text" v-model="data.btn.btn8.text" placeholder="Texte" size="lg"></b-form-input>
                            </b-form-group> 
                            <b-form-group label="Url" label-for="urlbtn8">
                                <b-form-input type="text" :id="data.btn.btn8.url" v-model="data.btn.btn8.url" placeholder="Url" size="lg"></b-form-input>
                            </b-form-group> 
                            </b-popover>
                    </template>
                    <button v-else class="btn btn-primary btn-rounded">
                        <span v-html="data.btn.btn8.text">
                        </span>
                    </button>
                    <!-- end btn8 -->
                </div>
            </div>
        </div>

        <!-- horizontal banner -->
        <div class="horizontal-banner d-flex align-items-center">
            <div class="container">
                <div class="d-flex flex-column justify-content-center align-items-center text-center">

                    <!-- text22 -->
                    <tt-editor v-if="modeEdit" :value="data.text.text22" @input="data.text.text22 = $event" ></tt-editor>
                    <span v-else v-html="data.text.text22">
                    </span>
                    <!-- end text22 -->

                    <!-- btn9 -->
                    <template v-if="modeEdit">
                            <button class="btn btn-primary btn-rounded" :id="'btn-9'" variant="primary" >
                            <span v-html="data.btn.btn9.text">
                            </span>  
                            
                            </button>
                            <b-popover :target="'btn-9'" placement="top" title="Edit!" triggers="hover focus" >
                                <b-form-group label="Text" label-for="textbtn9">
                                <b-form-input type="text" :id="data.btn.btn9.text" v-model="data.btn.btn9.text" placeholder="Texte" size="lg"></b-form-input>
                            </b-form-group> 
                            <b-form-group label="Url" label-for="urlbtn9">
                                <b-form-input type="text" :id="data.btn.btn9.url" v-model="data.btn.btn9.url" placeholder="Url" size="lg"></b-form-input>
                            </b-form-group> 
                            </b-popover>
                    </template>
                    <button v-else class="btn btn-primary btn-rounded">
                        <span v-html="data.btn.btn9.text">
                        </span>
                    </button>
                    <!-- end btn9 -->
                </div>
            </div>
        </div>
        
    </section>

    
</template>
<script>
import ttEditor from '../element/tt-editor.vue'
import addImage from '../functions/add-image.vue'
import data from '../json/cpts-accueil.json'

export default {
    name:"accueil",
    components:{
        ttEditor,
        addImage
    },
    data(){
        return{
            selected_image:null,
        }
    },
    props:{
        data:Object,
        modeEdit:Boolean
    },
    mounted(){
        console.log({data});
    }
}
</script>
<style scoped>
.cpts-numbers {
    position: relative;
    height: auto;
}
.cpts-numbers:before{
    content: " ";
    height: 80%;
    width: 100%;
    background-color: #8c1078;
    position: absolute;
}
.external-card .card {
    color: #fff;
    background-color: #8c1078;
    border: 2px solid white;
}
/* card-type-2 */
.card-type-2 .fa, .card-type-2 .mdi {
    color: #000;
    font-size: 4rem;
}
.card-type-2 .card {
    width: 100%;
}
.card-type-2 img {
    width: 5rem;
}
.card-rounded-1 {
    border-radius: 20px;
    background: #8c1078;
    color: white;
}
.card-rounded-2 {
    border: 2px solid #8c1078;
    border-radius: 20px;
    background: none;
    color: #8c1078;
}

/* card-type-3 */
.card-type-3 {
    background: rgb(226, 226, 226);
    border-top: 1px solid rgb(152, 152, 152);
    border-bottom: 1px solid rgb(152, 152, 152)
}
.card-type-3 .card {
    width: 100%;
    margin: 4rem;
}
/* card-type-4 */
.sign-in-img {
   position: absolute;
    width: 27vw;
    right: -67px;
    top: -91px;
    z-index: 2;
}
.card-type-4 .card-invi {
    margin: 2rem;
    width:100%;
}
/* horizonta-banner */
.horizontal-banner {
    min-height: 40vh;
    background: #8c1078;
}
.horizontal-banner h3 {
    color: #fff;
}
</style>